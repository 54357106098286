import { addPropertyControls, ControlType, RenderTarget } from "framer"
import { useEffect } from "react"
import { useFormStore } from "https://framer.com/m/SharedFormCode-HQOZ.js@pl3ng44Hc2Iq0qNooDOc"

/**
 * @framerSupportedLayoutWidth any
 * @framerSupportedLayoutHeight auto
 */
export default function HiddenField(props) {
    const { formId, name } = props
    const isCanvas = RenderTarget.current() === RenderTarget.canvas

    const [formState, setFormState] = useFormStore()

    async function getValue() {
        let value = null

        switch (props.type) {
            case "variable":
                switch (props.variableType) {
                    case "text":
                        value = props.valueText
                        break
                    case "number":
                        value = props.valueNumber
                        break
                    case "toggle":
                        value = props.valueToggle
                        break
                    case "link":
                        value = props.valueLink

                        if (value?.startsWith("/")) {
                            value = `https://${window.location.hostname}${value}`
                        }
                        break
                    case "color":
                        value = props.valueColor
                        if (value.startsWith("var(") && value.includes(", ")) {
                            value = value
                                .substring(value.indexOf(", ") + 2)
                                .slice(0, -1)
                        }
                        break
                    case "date":
                        value = new Date(props.valueDate)
                            .toISOString()
                            .split("T")[0]
                        break
                }
                break
            case "referrerUrl":
                value = document.referrer
                break
            case "urlParameter":
                value = new URLSearchParams(window.location.search).get(
                    props.urlParameter
                )
                break
            case "currentDateTime":
                switch (props.dateTimeType) {
                    case "dateTime":
                        value = new Date()
                            .toISOString()
                            .replace("T", " ")
                            .replace("Z", "")
                            .split(".")[0]
                        break
                    case "date":
                        value = new Date().toISOString().split("T")[0]
                        break
                    case "time":
                        value = new Date()
                            .toISOString()
                            .split("T")[1]
                            .replace("Z", "")
                            .split(".")[0]
                        break
                }

                break
            case "currentUrl":
                value = window.location.href
                break
            case "ipAddress":
                value = await getIPAddress()
                break
        }

        return value
    }

    useEffect(() => {
        async function update() {
            const value = await getValue()

            setFormState((prev) => {
                return {
                    [formId]: {
                        ...prev[formId],
                        [name]: {
                            class: "hiddenField",
                            value,
                            name,
                        },
                    },
                }
            })
        }

        update()
    }, [])

    if (!isCanvas) {
        return null
    }

    let text = ""
    switch (props.type) {
        case "variable":
            text = `${
                props.variableType.charAt(0).toUpperCase() +
                props.variableType.slice(1)
            } Variable`
            break
        case "referrerUrl":
            text = "Referrer URL"
            break
        case "urlParameter":
            text = `${props.urlParameter} URL Parameter`
            break
        case "currentDateTime":
            text = "Current Date & Time"
            break
        case "currentUrl":
            text = "Current URL"
            break
        case "ipAddress":
            text = "IP Address"
            break
    }

    return (
        <div
            style={{
                backgroundColor: "#F5F5F5",
                border: "1px solid rgba(0, 0, 0, 0.05)",
                borderRadius: 10,
                fontFamily: "Inter",
                fontWeight: 500,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: 4,
                padding: "10px 20px",
                textAlign: "center",
                textWrap: "nowrap",
                overflow: "hidden",
            }}
        >
            {text}
            <span
                style={{
                    opacity: 0.5,
                }}
            >
                Hidden Field
            </span>
        </div>
    )
}

HiddenField.displayName = "Hidden Field"

addPropertyControls(HiddenField, {
    formId: {
        type: ControlType.Number,
        defaultValue: 0,
        step: 1,
        min: 0,
        displayStepper: true,
        title: "Form ID",
        description: "Match with Form ID on Submit Button.",
    },
    name: {
        type: ControlType.String,
        defaultValue: "fieldName",
    },
    type: {
        type: ControlType.Enum,
        options: [
            "variable",
            "referrerUrl",
            "urlParameter",
            "currentDateTime",
            "currentUrl",
            "ipAddress",
        ],
        optionTitles: [
            "Variable",
            "Referrer URL",
            "URL Parameter",
            "Current Date & Time",
            "Current URL",
            "IP Address",
        ],
        title: "Value",
    },
    variableType: {
        type: ControlType.Enum,
        options: ["text", "number", "toggle", "link", "color", "date"],
        optionTitles: ["Text", "Number", "Toggle", "Link", "Color", "Date"],
        title: "Type",
        hidden: (props) => props.type !== "variable",
    },
    valueText: {
        type: ControlType.String,
        title: "Text",
        hidden: (props) => variableHidden(props, "text"),
    },
    valueNumber: {
        type: ControlType.Number,
        title: "Number",
        hidden: (props) => variableHidden(props, "number"),
    },
    valueToggle: {
        type: ControlType.Boolean,
        title: "Toggle",
        hidden: (props) => variableHidden(props, "toggle"),
    },
    valueLink: {
        type: ControlType.Link,
        title: "Link",
        hidden: (props) => variableHidden(props, "link"),
    },
    valueColor: {
        type: ControlType.Color,
        title: "Color",
        hidden: (props) => variableHidden(props, "color"),
    },
    valueDate: {
        type: ControlType.Date,
        title: "Date",
        hidden: (props) => variableHidden(props, "date"),
    },
    urlParameter: {
        type: ControlType.String,
        placeholder: "Parameter Name",
        description: "abc.com?*param*=123",
        title: "URL Parameter",
        hidden: (props) => props.type !== "urlParameter",
    },
    dateTimeType: {
        type: ControlType.Enum,
        defaultValue: "dateTime",
        options: ["dateTime", "date", "time"],
        optionTitles: ["Date & Time", "Date Only", "Time Only"],
        displaySegmentedControl: true,
        segmentedControlDirection: "vertical",
        title: "Type",
        hidden: (props) => props.type !== "currentDateTime",
    },
})

function variableHidden(props, variableType) {
    return props.type !== "variable" || props.variableType !== variableType
}

async function getIPAddress() {
    try {
        const response = await fetch("https://api.ipify.org?format=json")
        const data = await response.json()
        return data.ip
    } catch (error) {
        console.error("Error fetching IP address:", error)
        return ""
    }
}
